// import React, {lazy} from 'react'

import Vader from "./vader/index"
import tagger from "./Tagger"
import dataHedonometer from "./models/hed.json"
import dataAnew from "./models/notanew.json"
import mpqa from "./models/mpqanew.json"

const addMatch = (matchMap, word, sentiment) => {
  let wordLower = word.toLowerCase()
  if (wordLower in matchMap) {
    matchMap[wordLower].push(sentiment)
  } else {
    matchMap[wordLower] = [sentiment]
  }
}

const addToWordMap = (word, score, wordMap) => {
  if (!(word in wordMap)) {
    wordMap[word] = {
      score: score,
      count: 1,
    }
  } else {
    let newWord = wordMap[word]

    // if the word has the same score, increment count
    if (score === newWord.score) {
      wordMap[word] = { ...newWord, count: newWord.count + 1 }
    } else {
      // find how many variants already exist
      let variantCount =
        Object.keys(wordMap).filter(wordKey => wordKey.startsWith(`${word}_`))
          .length + 1

      if (variantCount > 1) {
        for (let i = 1; i < variantCount; i++) {
          let existIndex = `${word}_${i + 1}`
          let existWord = wordMap[existIndex]
          if (score === existWord.score) {
            wordMap[existIndex] = { ...existWord, count: existWord.count + 1 }
            return
          }
        }
      }

      wordMap[`${word}_${variantCount + 1}`] = { score: score, count: 1 }
    }
  }
}

const Scorer = (text, model) => {
  let wordMap = {}
  let scoreMap = {}
  let matchMap = {}
  let wordArray = text
    .toLowerCase()
    .split(/\s+/)
    .map(word => word.replace(/[^\w\-\']/g, ""))
    .filter(word => !(word === "'" || word === "-"))

  let wc = wordArray.length

  if (model === "vader") {
    const vaderOutput = Vader.polarity_scores(text)

    scoreMap = vaderOutput.scores
    for (let i = 0; i < vaderOutput.sentiments.length; i++) {
      const score = vaderOutput.sentiments[i]
      const word = vaderOutput.words[i]

      if (score !== 0) {
        if (score < 0) {
          addMatch(matchMap, word, -1)
        } else if (score > 0) {
          addMatch(matchMap, word, 1)
        } else {
          addMatch(matchMap, word, 0)
        }
      }
      addToWordMap(word.toLowerCase(), score, wordMap)
    }

    // console.log(vaderOutput)
  } else {
    let lexicon
    switch (model) {
      case "hedonometer":
      case "anewext":
        lexicon = model === "hedonometer" ? dataHedonometer : dataAnew

        let scoreTotal = 0
        let scoreCount = 0

        for (let i = 0; i < wordArray.length; i++) {
          const query = wordArray[i]

          if (query in lexicon) {
            const score = lexicon[query]

            addToWordMap(query, score, wordMap)
            if (score <= 4 || score >= 6) {
              scoreTotal += score
              scoreCount++
              if (score <= 4) {
                addMatch(matchMap, query, -1)
              } else if (score >= 6) {
                addMatch(matchMap, query, 1)
              }
            }
          }
        }

        let avgScore = scoreTotal / scoreCount
        scoreMap["Sentiment Avg."] = avgScore

        break

      case "mpqa":
        lexicon = mpqa
        const tagOutput = tagger(text)

        let matchTotal = 0
        let posTotal = 0
        let negTotal = 0
        // console.log(wordArray)
        // console.log(tags)

        for (let i = 0; i < tagOutput.length; i++) {
          const word = tagOutput[i][0]
          const tag = tagOutput[i][1]
          const verbInf = tagOutput[i][2]

          let matchWord = verbInf ? verbInf : word

          const match = mpqa[matchWord]
          if (match) {
            const anyMatch = match["x"]
            const tagMatch = match[tag]
            if (tagMatch || anyMatch) {
              let matchScore
              if (tagMatch) {
                matchScore = tagMatch[0]
                addToWordMap(`${word}_${tag}`, matchScore, wordMap)
                addMatch(matchMap, word, matchScore)
              } else {
                matchScore = anyMatch[0]
                addToWordMap(`${word}_any`, matchScore, wordMap)
                addMatch(matchMap, word, matchScore)
              }
              matchTotal += 1
              if (matchScore < 0) {
                negTotal += 1
              } else if (matchScore > 0) {
                posTotal += 1
              }
            } else {
              addMatch(matchMap, word, 0)
            }
          }
        }

        scoreMap.Total = matchTotal
        scoreMap.Positive = posTotal
        scoreMap.Negative = negTotal

        break

      case "liwc":
        // console.log(vaderOutput)
        break
    }
  }
  return {
    scores: scoreMap,
    wordMap: wordMap,
    match: matchMap,
    wc: wc,
  }
}

export default Scorer
