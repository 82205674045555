import React, { useContext, useState, useEffect } from "react"

const modelMap = {
  0: "hedonometer",
  1: "anewext",
  2: "vader",
  3: "mpqa",
  // 4: "liwcalike",
}

const AppContext = React.createContext()

const AppProvider = ({ children }) => {
  const [loading, setLoading] = useState(true)

  const [inputTxt, setInputTxt] = useState("")
  const [score, setScore] = useState({})
  const [words, setWords] = useState({})
  const [source, setSource] = useState(0)
  const [wc, setWc] = useState(0)
  const [model, setModel] = useState("hedonometer")

  useEffect(() => {
    if (loading) {
      // initialize condition
      setInputTxt(
        "Sample Text\nPositive: The happy dog had a fun party with friends.\nNegative: The sad eggplant missed it because it was sick.\nNegation: This bread is good. That one is not good.\nPart-of-speech: The fine dining restaurant will fine no-shows."
      )
      setLoading(false)
    }
  }, [loading])

  useEffect(() => setModel(modelMap[source]), [source])

  return (
    <AppContext.Provider
      value={{
        loading,
        inputTxt,
        setInputTxt,
        score,
        setScore,
        words,
        setWords,
        source,
        setSource,
        model,
        wc,
        setWc,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export const useGlobalContext = () => {
  return useContext(AppContext)
}

export { AppContext, AppProvider }
