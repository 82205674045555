import React from "react"
import ChartHist from "./ChartHist"
import ChartPie from "./ChartPie"

const ChartArea = ({ model }) => {
  if (["hedonometer", "anewext", "vader"].includes(model)) {
    return <ChartHist />
  } else if (["mpqa", "liwcalike"].includes(model)) {
    return <ChartPie />
  }
}

export default ChartArea
