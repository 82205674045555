import React, { CSSProperties } from "react"
import {
  PieChart,
  Pie,
  Cell,
  Legend,
  Tooltip,
  ResponsiveContainer,
} from "recharts"

const finalStyle = {
  margin: 0,
  padding: 10,
  backgroundColor: "#fff",
  border: "1px solid #ccc",
  whiteSpace: "normal",
  maxWidth: "100px",
  fontSize: "0.7rem",
}

const finalLabelStyle = {
  margin: 0,
  fontWeight: "bold",
}

const finalDescStyle = {
  margin: 0,
  lineHeight: "110%",
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const score = payload[0].payload.name
    const ttLabel = `${score}`

    const count = payload[0].payload.count
    const wordString = payload[0].payload.words.join(", ")

    return (
      <div className="custom-tooltip" style={finalStyle}>
        <p className="label" style={finalLabelStyle}>
          {`${ttLabel} (${count})`}
        </p>
        <p className="desc" style={finalDescStyle}>{`${wordString}`}</p>
      </div>
    )
  }

  return null
}

// .pos-word {
//   color:#22AA22
// }

// .neg-word {
//   color:#AA2222
// }

const colorMap = {
  default: "#8884AE",
  red: "#AA2222",
  green: "#22AA22",
  yellow: "#FFBB28",
}

const DisplayPie = ({ data }) => {
  // console.log(data)
  return (
    <div className="chart-container">
      <div className="chart-box">
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width={500} height={200}>
            <Pie
              dataKey="count"
              animationBegin={0}
              animationDuration={300}
              // isAnimationActive={false}
              data={data}
              cx="50%"
              cy="50%"
              outerRadius={60}
              fill="#8884d8"
              label
            >
              {data.map((el, index) => (
                <Cell key={`cell-${index}`} fill={colorMap[el.color]} />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
          </PieChart>

          {/* <BarChart
            width={500}
            height={400}
            data={data}
            margin={{
              top: 10,
              right: 35,
              left: 0,
              bottom: 20,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="name"
              label={{
                value: "Sentiment Score",
                position: "insideBottom",
                offset: -20,
              }}
            />
            <YAxis
              label={{
                value: "#",
                // angle: -90,
                position: "insideLeft",
                offset: 20,
              }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Bar
              type="monotone"
              dataKey="count"
              // stroke="#8884d8"
              // fill="#8884d8"
            >
              {data.map((el, index) => (
                <Cell key={`cell-${index}`} fill={colorMap[el.color]} />
              ))}
            </Bar>
          </BarChart> */}
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default DisplayPie
