import nlp from "compromise"

const Tagger = text => {
  const doc = nlp(text)

  // console.log(doc)

  const t2 = doc.terms()
  const outputArray = t2.map(term => {
    const tagDict = Object.entries(term.out("tags")[0])[0]
    const word = tagDict[0]
    const tags = tagDict[1]

    const verbInfo = term.verbs()
    let wordTag
    let verbInf

    // first check if verb
    if (verbInfo.length > 0) {
      wordTag = "v"

      // get infinitive form of the verb to search in dictionary
      verbInf = verbInfo.toInfinitive().text()
    } else if (tags.includes("Noun")) {
      wordTag = "n"
    } else if (tags.includes("Adjective")) {
      wordTag = "adj"
    } else if (tags.includes("Adverb")) {
      wordTag = "adv"
    } else {
      wordTag = "x"
    }
    return [word, wordTag, verbInf]
  })

  return outputArray
}

export default Tagger
