import React from "react"

import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Box from "@mui/material/Box"

import { useGlobalContext } from "./context"

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    wrapped: true,
  }
}

const BoxTabs = () => {
  const { source, setSource } = useGlobalContext()

  const handleChange = (event, newValue) => {
    setSource(newValue)
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={source}
          onChange={handleChange}
          aria-label="Sentiment models"
          variant="scrollable"
          // allowScrollButtonsMobile
        >
          <Tab label="Hedonometer" {...a11yProps(0)} />
          <Tab label="ANEW-EXT" {...a11yProps(1)} />
          <Tab label="VADER" {...a11yProps(2)} />
          <Tab label="MPQA" {...a11yProps(3)} />
          {/* <Tab label="LIWC-ALIKE" {...a11yProps(4)} /> */}
        </Tabs>
      </Box>
    </Box>
  )
}

export default BoxTabs
