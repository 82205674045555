import React from "react"
import ModelDetails from "../content/ModelDetails"

const Description = ({ model }) => {
  const details = ModelDetails()
  const citation = details[model]["citation"]

  return <div className="description-box">{JSON.stringify(citation)}</div>
}

export default Description
