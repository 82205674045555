import React from "react"

import ScoreTable from "./ScoreTable"

import BoxTabs from "./BoxTabs"
import TextBox from "./TextBox"
import ChartArea from "./visualization/ChartArea"
import Description from "./Description"

import { useGlobalContext } from "./context"

// import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid"

import "./style.css"

const PageContents = () => {
  const {
    score,
    setScore,
    words,
    setWords,
    source,
    setSource,
    model,
  } = useGlobalContext()

  // console.log(words)

  return (
    <div>
      <h1>Sentiment Sandbox</h1>

      {/* <div className="display-text">
        {options.map((opt, idx) => (
          <div key={idx}>
            <label className="option-box">
              <input
                name={"model"}
                type={"radio"}
                value={idx}
                onChange={() => setSource(opt)}
              />
              <span className="option-label">
                <strong>{opt}</strong>
              </span>
            </label>
          </div>
        ))}
      </div> */}
      <div>
        <BoxTabs />
        <TextBox />
      </div>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className="score-box">
            <ScoreTable score={score} />
          </div>
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={6}>
          {/* <textarea onChange={boxHandler} className={"text-box"} /> */}
        </Grid>
        <Grid item xs={6}>
          {/* <span className={"display-text"}>{displayHtml}</span> */}
        </Grid>
      </Grid>
      <ChartArea model={model} />
      <Description model={model} />
      {/* <BasicTable words={words} /> */}
      <div></div>
    </div>
  )
}

export default PageContents
