import React, { useState, useEffect } from "react"
import { useGlobalContext } from "../context"

import DisplayHist from "./DisplayHist"

const getOptions = model => {
  let options = {
    min: 1,
    max: 8,
    gap: 1,
  }

  if (model === "vader") {
    options = {
      min: -4,
      max: 4,
      gap: 1,
    }
  }

  return options
}

const initBucket = (model, score) => {
  let color = "default"

  if (model === "hedonometer" || model === "anewext") {
    color = score < 4 ? "red" : score > 5 ? "green" : "default"
  } else if (model === "vader") {
    color = score < 0 ? "red" : score > 0 ? "green" : "default"
  }

  const bucketObj = {
    name: score,
    count: 0,
    words: [],
    color: color,
  }

  return bucketObj
}

const ChartHist = () => {
  const { words, model } = useGlobalContext()
  const [hist, setHist] = useState({ bars: [] })

  useEffect(() => {
    const bucketObj = {}

    const wordList = Object.keys(words)

    for (let i = 0; i < wordList.length; i++) {
      const word = wordList[i]
      const val = words[word]

      const bucketScore = Math.floor(val.score)

      if (!(bucketScore in bucketObj)) {
        bucketObj[bucketScore] = initBucket(model, bucketScore)
      }
      const match = bucketObj[bucketScore]
      match.count += val.count
      match.words.push(val.count > 1 ? `${word}(${val.count})` : `${word}`)
    }

    // Fill array with empty buckets
    const histOptions = getOptions(model)

    for (
      let bucketScore = histOptions.min;
      bucketScore < histOptions.max + 1;
      bucketScore += histOptions.gap
    ) {
      if (!(bucketScore in bucketObj)) {
        bucketObj[bucketScore] = initBucket(model, bucketScore)
      }
    }

    if (model === "vader") {
      bucketObj[0].count = 0
    }

    const sortedBuckets = Object.values(bucketObj).sort(
      (a, b) => a.name - b.name
    )

    setHist({
      bars: sortedBuckets,
    })
  }, [words, model])

  return (
    <div>
      <DisplayHist data={hist.bars} />
    </div>
  )
}

export default ChartHist
