import React, { CSSProperties } from "react"
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts"

const finalStyle = {
  margin: 0,
  padding: 10,
  backgroundColor: "#fff",
  border: "1px solid #ccc",
  whiteSpace: "normal",
  maxWidth: "100px",
  fontSize: "0.7rem",
}

const finalLabelStyle = {
  margin: 0,
  fontWeight: "bold",
}

const finalDescStyle = {
  margin: 0,
  lineHeight: "110%",
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const score = payload[0].payload.name
    const ttLabel = `${score} ~ ${score + 1}`

    const count = payload[0].payload.count
    const wordString = payload[0].payload.words.join(", ")

    return (
      <div className="custom-tooltip" style={finalStyle}>
        <p className="label" style={finalLabelStyle}>
          {`${ttLabel} (${count})`}
        </p>
        <p className="desc" style={finalDescStyle}>{`${wordString}`}</p>
      </div>
    )
  }

  return null
}

// .pos-word {
//   color:#22AA22
// }

// .neg-word {
//   color:#AA2222
// }

const colorMap = {
  default: "#8884AE",
  red: "#AA2222",
  green: "#22AA22",
}

const DisplayHist = ({ data }) => {
  return (
    <div className="chart-container">
      <div className="chart-box">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={400}
            data={data}
            margin={{
              top: 10,
              right: 35,
              left: 0,
              bottom: 25,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              className="axis-text"
              dataKey="name"
              label={{
                value: "Sentiment Score",
                position: "insideBottom",
                offset: -20,
              }}
            />
            <YAxis
              className="axis-text"
              label={{
                value: "#",
                // angle: -90,
                position: "insideLeft",
                offset: 20,
              }}
            />
            <Tooltip content={<CustomTooltip />} />
            {/* <Tooltip /> */}
            <Bar
              type="monotone"
              dataKey="count"
              // stroke="#8884d8"
              // fill="#8884d8"
            >
              {data.map((el, index) => (
                <Cell key={`cell-${index}`} fill={colorMap[el.color]} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default DisplayHist
