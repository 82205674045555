import * as React from "react"

import Layout from "../components/layout"
import PageContents from "../components/PageContents"
import { AppProvider } from "../components/context"

const IndexPage = () => {
  return (
    <Layout>
      <AppProvider>
        <PageContents />
      </AppProvider>
    </Layout>
  )
}

export default IndexPage
