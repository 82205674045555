const Highlighter = (text, match, model) => {
  let outputText = text

  let wordUnique = ["vader", "mpqa"].includes(model) ? true : false

  const wordList = Object.keys(match)

  for (let i = 0; i < wordList.length; i++) {
    const word = wordList[i]
    const sentArray = match[word]

    const queryWord = model === "mpqa" ? word.split("_")[0] : word

    const regex = new RegExp(`\\b(${queryWord})\\b`, "gi")

    const loopLimit = wordUnique ? sentArray.length : 1

    for (let i = 0; i < loopLimit; i++) {
      const sentiment = sentArray[i]
      const wordClass =
        sentiment > 0 ? "pos-word" : sentiment < 0 ? "neg-word" : "neu-word"

      let counter = 0

      // if same words can have different scores, match by nth occurrence of word
      if (wordUnique) {
        outputText = outputText.replace(regex, match =>
          counter++ === i ? `<span class=${wordClass}>${match}</span>` : match
        )
      }
      // if words have fixed scores, replace all occurrences
      else {
        outputText = outputText.replace(
          regex,
          `<span class=${wordClass}>$1</span>`
        )
      }
    }
  }

  return outputText
}

export default Highlighter
