import React from "react"
// import { makeStyles } from "@mui/material/styles"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableRow from "@mui/material/TableRow"

export default function BasicTable({ score }) {
  // const classes = useStyles()
  const scoreArray = Object.entries(score)

  return (
    <TableContainer>
      <Table className={"temp"} aria-label="simple table">
        <TableBody>
          {scoreArray.map(scoreObj => {
            const scoreVal = scoreObj[1]
            const scoreName = scoreObj[0]
            const scoreValDec = scoreVal.toFixed
              ? Number(scoreVal.toFixed(3))
              : scoreVal
            return (
              <tr key={scoreName}>
                <td>{String(scoreName)}</td>
                <td>{String(scoreValDec)}</td>
              </tr>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
