import React, { useState, useEffect } from "react"
import { useGlobalContext } from "../context"

import DisplayPie from "./DisplayPie"

const getOptions = model => {
  let options = {
    min: 1,
    max: 8,
    gap: 1,
  }

  if (model === "vader") {
    options = {
      min: -4,
      max: 4,
      gap: 1,
    }
  }

  return options
}

const initBucket = score => {
  let color = "default"
  let name = "no match"

  if (score === -1) {
    name = "negative"
    color = "red"
  } else if (score === 0) {
    name = "neutral"
    color = "yellow"
  } else if (score === 1) {
    name = "positive"
    color = "green"
  }

  const bucketObj = {
    name: name,
    count: 0,
    words: [],
    color: color,
    score,
    score,
  }

  return bucketObj
}

const ChartPie = () => {
  const { words, model, wc } = useGlobalContext()
  const [pie, setPie] = useState({ slices: [] })

  // console.log(words)

  useEffect(() => {
    const slices = {}

    const wordList = Object.keys(words)

    for (let i = 0; i < wordList.length; i++) {
      const word = wordList[i]
      const val = words[word]
      const score = val.score

      if (!(score in slices)) {
        slices[score] = initBucket(score)
      }

      const match = slices[score]
      match.count += val.count
      match.words.push(val.count > 1 ? `${word}(${val.count})` : `${word}`)
    }

    const nmCount = wc - wordList.length
    slices[99] = initBucket(99)
    slices[99].count = nmCount

    const sortedBuckets = Object.values(slices).sort(
      (a, b) => a.score - b.score
    )

    setPie({
      slices: sortedBuckets,
    })
  }, [words, model])

  return (
    <div>
      <DisplayPie data={pie.slices} />
    </div>
  )
}

export default ChartPie
