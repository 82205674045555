import React from "react"

import { useState, useEffect } from "react"

import Editor from "react-simple-code-editor"

import Scorer from "./scripts/Scorer"
import Highlighter from "./scripts/Highlighter"
import { useGlobalContext } from "./context"

const TextBox = () => {
  const {
    inputTxt,
    setInputTxt,
    score,
    setScore,
    words,
    setWords,
    setSource,
    setWc,
    model,
  } = useGlobalContext()

  const [match, setMatch] = useState({})

  useEffect(() => {
    const scoreOutput = Scorer(inputTxt, model)

    setScore(scoreOutput.scores)
    setWords(scoreOutput.wordMap)
    setMatch(scoreOutput.match)
    setWc(scoreOutput.wc)
  }, [inputTxt, model])

  const hlHandler = text => {
    return Highlighter(text, match, model)
  }

  return (
    <div>
      <div className="editor-container">
        <Editor
          value={inputTxt}
          onValueChange={inputTxt => setInputTxt(inputTxt)}
          highlight={hlHandler}
          padding={10}
          style={{
            fontFamily: "georgia, serif",
            fontSize: "1rem",
            textSizeAdjust: "100%",
          }}
          className="editor-box"
        />
      </div>
    </div>
  )
}

export default TextBox
